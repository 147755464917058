import {
  Box,
  Button,
  HiddenFromView,
  Text,
  ResponsiveSection,
  useAtBreakpoint,
  getBreakpoints,
  type TextProps,
} from '@stitch-fix/mode-react';
import { isNonEmptyString } from '../../utils/helpers';
import styles from './styles.module.scss';

type TextItem = {
  text: string;
  family: TextProps['family'];
};

export type DisplayTextProps = {
  sectionTitle: string;
  items: TextItem[];
  button: {
    href: string;
    title: string;
  };
  backgroundColor: string;
};

export const DisplayText = ({
  items,
  sectionTitle,
  button,
  backgroundColor,
}: DisplayTextProps) => {
  const isLargeScreens = useAtBreakpoint(getBreakpoints().lg);

  const shouldRenderButton =
    isNonEmptyString(button.href) && isNonEmptyString(button.title);

  return (
    <Box as="section">
      <HiddenFromView>
        <Text setting="title-xlarge" as="h2">
          {sectionTitle}
        </Text>
      </HiddenFromView>
      <Box
        style={{
          backgroundColor,
        }}
        py={{ sm: 1.5, lg: 3 }}
      >
        <ResponsiveSection width="xxlarge">
          <Box className={styles.textContainer}>
            {items.map(item => (
              <Text
                key={item.text}
                height="standard"
                family={item.family}
                scale={isLargeScreens ? '10' : '5'}
                style={{ textTransform: 'uppercase' }}
              >
                {item.text}
              </Text>
            ))}
          </Box>
          {shouldRenderButton && (
            <Box mt={1.5}>
              <Button as="a" href={button.href} variant="filled-staple">
                {button.title}
              </Button>
            </Box>
          )}
        </ResponsiveSection>
      </Box>
    </Box>
  );
};
