import {
  Accordion,
  Box,
  Divider,
  ResponsiveSection,
  Stack,
} from '@stitch-fix/mode-react';
import { type ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ModuleWrapper, type ModuleWrapperProps } from '../ModuleWrapper';
import styles from './styles.module.scss';

type AccordionItem = {
  heading: string;
  body: ContentStackJSONRichText;
  defaultIsExpanded?: boolean;
};

export type AccordionGroupProps = {
  items: AccordionItem[];
} & Omit<ModuleWrapperProps, 'children'>;

export const AccordionGroup = ({
  moduleTitle,
  moduleFooter,
  alignment,
  items,
}: AccordionGroupProps) => {
  return (
    <ResponsiveSection width="xxlarge">
      <ModuleWrapper
        alignment={alignment}
        moduleFooter={moduleFooter}
        moduleTitle={moduleTitle}
      >
        <Stack spacing={1}>
          {items.map(item => (
            <Box className={styles.accordionItem} key={item.heading}>
              <Accordion
                heading={item.heading}
                headingLevel="none"
                headingSize="title-small"
                expandIconAlignment="fixed-end"
                defaultIsExpanded={item.defaultIsExpanded}
                color="navy"
              >
                <JsonRteRenderer
                  data={item.body}
                  options={{ textProps: { p: { color: 'blue-45' } } }}
                />
              </Accordion>
              <Divider mt={1} color="navy" variant="subtle" />
            </Box>
          ))}
        </Stack>
      </ModuleWrapper>
    </ResponsiveSection>
  );
};
