import { type Color, Link, Text, type TextProps } from '@stitch-fix/mode-react';
import { A, G } from '@mobily/ts-belt';
import React from 'react';
import { isNonEmptyString } from '../../utils/helpers/isNonEmptyString';
import type {
  ChildBlock,
  ChildText,
  ChildLink,
  ChildSpan,
  ContentStackJSONRichText,
} from '../../../scripts/schemaGenerator/fields/rte';

type JsonRteRendererOptions = {
  textProps: {
    [H in HeadingTag | 'p']?: TextProps<H>;
  };
  textSizeMap?: 'display' | 'title';
  color?: Color;
};

export type JsonRteRendererProps = {
  data: ContentStackJSONRichText;
  options?: JsonRteRendererOptions;
};

const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
const textAlignments = ['left', 'center', 'right', 'justify'] as const;
const validClassNames = ['uppercase'] as const;

type HeadingTag = (typeof headingTags)[number];
type TextAlignment = (typeof textAlignments)[number];

const isHeadingTag = (item: string): item is HeadingTag =>
  A.includes(headingTags, item);

const isTextAlignment = (item: string): item is TextAlignment =>
  A.includes(textAlignments, item);

const isParagraphTag = (item: string): item is 'p' => item === 'p';

const isSpanTag = (item: string): item is 'span' => item === 'span';

const isTextElement = (element: unknown): element is ChildText =>
  typeof element === 'object' && element !== null && 'text' in element;

const isValidClassName = (className?: string) =>
  G.isString(className) && A.includes(validClassNames, className.trim());

export const isEmpty = (
  data: (ChildBlock | ChildText | ChildLink | ChildSpan)[] | undefined,
): boolean => {
  if (!Array.isArray(data)) {
    return true;
  }

  return data.every(child => {
    if ('children' in child && Array.isArray(child.children)) {
      return isEmpty(child.children);
    }

    return isTextElement(child) && !isNonEmptyString(child.text);
  });
};

const getTextStyle = (
  child: ChildBlock | ChildLink | ChildText | ChildSpan,
) => {
  let style: React.CSSProperties | undefined;

  if (
    'attrs' in child &&
    'class-name' in child.attrs &&
    isValidClassName(child.attrs['class-name'])
  ) {
    style = { textTransform: 'uppercase' };
  }

  return style;
};

const getTextAlignment = (
  child: ChildBlock | ChildLink | ChildText | ChildSpan,
) => {
  let textAlignment: TextAlignment = 'left';

  if (
    'attrs' in child &&
    'style' in child.attrs &&
    child.attrs.style !== undefined &&
    'text-align' in child.attrs.style &&
    isTextAlignment(child.attrs.style['text-align'])
  ) {
    textAlignment = child.attrs.style['text-align'];
  }

  return textAlignment;
};

const TEXT_TYPE_SIZE_MAP: Record<HeadingTag | 'p', TextProps['setting']> = {
  h1: 'title-xlarge',
  h2: 'title-large',
  h3: 'title-medium',
  h4: 'title-small',
  h5: 'title-xsmall',
  h6: 'title-xxsmall',
  p: 'body-medium',
};

const REBRAND_TEXT_TYPE_SIZE_MAP: Record<
  HeadingTag | 'p',
  TextProps['setting']
> = {
  h1: 'display-xlarge',
  h2: 'display-large',
  h3: 'display-medium',
  h4: 'title-small',
  h5: 'title-xsmall',
  h6: 'title-xxsmall',
  p: 'body-medium',
};

const getDefaultOptions = (): JsonRteRendererOptions => {
  const defaults = {
    spacingBottom: true,
    color: 'gray-16' as const,
  };

  const headings = headingTags.reduce((acc, heading) => {
    return {
      ...acc,
      [heading]: {
        ...defaults,
      },
    };
  }, {});

  return {
    textSizeMap: 'title',
    textProps: {
      ...headings,
      p: {
        ...defaults,
      },
    },
  };
};

const mergeDefaultOptions = (
  options: JsonRteRendererOptions,
  textProp: keyof JsonRteRendererOptions['textProps'],
) => {
  const defaults = getDefaultOptions();

  return {
    ...defaults.textProps[textProp],
    color: options.color,
    ...options.textProps[textProp],
  };
};

export const JsonRteRenderer = ({
  data,
  options = getDefaultOptions(),
}: JsonRteRendererProps) => {
  const renderText = (child: unknown) => {
    if (isTextElement(child)) {
      let node: React.ReactNode = React.createElement(
        React.Fragment,
        undefined,
        child.text,
      );

      if (child.bold) {
        node = React.createElement('strong', undefined, node);
      }

      if (child.italic) {
        node = React.createElement('em', undefined, node);
      }

      if (child.break) {
        node = React.createElement(
          React.Fragment,
          undefined,
          node,
          React.createElement('br'),
        );
      }

      if (child.classname) {
        const classNames = child.classname.split(' ');

        if (classNames.includes('uppercase')) {
          node = React.createElement(
            'span',
            { style: { textTransform: 'uppercase' } },
            node,
          );
        }
      }

      return node;
    }

    return '';
  };

  const parseElementChildren = (
    child: ChildBlock | ChildLink | ChildSpan,
    parser: typeof renderItems | typeof renderText,
  ) => {
    return child.children.map((el, index) => (
      // List is static and won't change once rendered
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={`${child.uid}-${index}`}>
        {parser(el)}
      </React.Fragment>
    ));
  };

  const applyClassnameOverrides = (
    child: ChildBlock | ChildLink | ChildText | ChildSpan,
  ) => {
    const includesDisclaimerText =
      'type' in child &&
      isParagraphTag(child.type) &&
      child.children.some(
        c => 'classname' in c && c.classname === 'disclaimer',
      );

    if (includesDisclaimerText) {
      return { setting: 'body-small' as const };
    }

    return undefined;
  };

  const renderItems = (
    child: ChildBlock | ChildLink | ChildText | ChildSpan,
  ) => {
    if ('type' in child && child.type === 'a') {
      return (
        <Link href={child.attrs.url} target={child.attrs.target}>
          {parseElementChildren(child, renderItems)}
        </Link>
      );
    }

    if (
      'type' in child &&
      (isHeadingTag(child.type) || isParagraphTag(child.type))
    ) {
      return (
        <Text
          as={child.type}
          setting={
            options.textSizeMap === 'display'
              ? REBRAND_TEXT_TYPE_SIZE_MAP[child.type]
              : TEXT_TYPE_SIZE_MAP[child.type]
          }
          align={getTextAlignment(child)}
          style={getTextStyle(child)}
          {...mergeDefaultOptions(options, child.type)}
          {...applyClassnameOverrides(child)}
        >
          {parseElementChildren(child, renderItems)}
        </Text>
      );
    }

    if ('type' in child && isSpanTag(child.type)) {
      return parseElementChildren(child, renderText);
    }

    // if we reached here, we have a text element and don't need to recurse
    return renderText(child);
  };

  return (
    <>
      {data?.children.map(child => (
        <React.Fragment key={child.uid}>{renderItems(child)}</React.Fragment>
      ))}
    </>
  );
};
