import { m } from 'framer-motion';
import { CloudinaryPicture } from '../CSCloudinaryAsset/Picture';
import type { GalleryHeroProps } from './GalleryHero';
import styles from './styles.module.scss';

export type MediaItemProps = {
  slideIndex: number;
  activeSlideIndex: number;
  initialAnimate: boolean;
} & Pick<GalleryHeroProps, 'items'>;

export const MediaItem = ({
  items,
  slideIndex,
  activeSlideIndex,
  initialAnimate,
}: MediaItemProps) => {
  const slide = items[slideIndex];

  if (slide?.media === undefined) {
    return null;
  }

  const getAnimation = (currentSlideIndex: number) => {
    const isCurrentActive = activeSlideIndex === currentSlideIndex;
    const isNextActive = activeSlideIndex === currentSlideIndex + 1;

    if (isCurrentActive || isNextActive) {
      return { opacity: 1, scale: 1.05, zIndex: 1 };
    }

    return { opacity: 0, scale: 1, zIndex: 0 };
  };

  return (
    <m.div
      initial={
        initialAnimate
          ? {
              opacity: 0,
              scale: 1,
              zIndex: 0,
            }
          : false
      }
      animate={getAnimation(slideIndex)}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={styles.mediaItem}
    >
      <CloudinaryPicture
        aria-hidden={activeSlideIndex !== slideIndex}
        alt={slide.media.desktop.alt ?? ''}
        sources={[
          {
            minWidth: 0,
            cloudinaryAsset: slide.media.mobile.cloudinaryAsset,
          },
          {
            minWidth: 900,
            cloudinaryAsset: slide.media.desktop.cloudinaryAsset,
          },
        ]}
        imageProps={{
          fetchPriority: 'high',
        }}
      />
    </m.div>
  );
};
