import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  type MutableRefObject,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useMeasure } from 'react-use';
import { Box, useMedia, generateCloudinaryUrl } from '@stitch-fix/mode-react';
import { useInView } from 'react-intersection-observer';

export type CloudinaryVideoProps = {
  videoUrl: string;
  aspectRatio?: string;
  videoLabel?: string;
};

export type CloudinaryVideoHandle = {
  play: () => void;
  pause: () => void;
  restart: () => void;
};

/**
 * get width of video based upon measure
 * round at a decent interval to avoid cloudinary transformation fees
 * round down to rely on upscaling
 */
const videoWidth = (width: number) => {
  const interval = 100;

  const calculatedValue = Math.ceil(width / interval) * interval;

  return Math.max(calculatedValue);
};

/**
 * A simple video player that uses Cloudinary to serve video content.
 * As opposed to the CloudinaryVideoPlayer component, this component does not
 * render controls or captions and is intended for decorative video content.
 */
export const CloudinaryVideo = forwardRef<
  CloudinaryVideoHandle,
  CloudinaryVideoProps
>(({ videoUrl, videoLabel, aspectRatio = '' }, ref) => {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const [inViewRef, inView] = useInView({ threshold: 0 });
  const videoRef: MutableRefObject<HTMLMediaElement | undefined> = useRef();
  const [videoPlayerContainer, { width }] = useMeasure<HTMLDivElement>();
  const roundedWidth = useMemo(() => videoWidth(width), [width]);
  const [isPlaying, setIsPlaying] = useState(false);

  // inViewRef behaves a little differently than `ref` does. It doesn't give us access
  // to the html object, which we need to control video playback programatically.
  // Instead, this method will set both refs.
  const setRefs = useCallback(
    node => {
      videoRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const extensionPattern = /\.\w{3,4}$/;

  const baseUrl = videoUrl.split('/upload/')[0];
  const mediaPath = videoUrl.split('/upload/')[1];

  const cloudinaryVideoUrl = generateCloudinaryUrl(
    `${baseUrl}/upload`,
    mediaPath,
    {
      f: 'auto',
      q: 'auto:best',
      w: roundedWidth || 450,
      // @ts-expect-error - CloudinaryImageTransforms type does not have the
      // start offset qualifier but the function does support it
      so: '0',
    },
  );

  const posterUrl = cloudinaryVideoUrl.replace(extensionPattern, '.jpg');
  const videoMp4 = cloudinaryVideoUrl.replace(extensionPattern, '.mp4');
  const videoWebm = cloudinaryVideoUrl.replace(extensionPattern, '.webm');

  useEffect(() => {
    if (!ref && videoRef.current) {
      if (inView && !prefersReducedMotion) {
        if (!isPlaying) {
          // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
          videoRef.current.play().then(() => {
            setIsPlaying(true);
          });
        }
      } else if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [ref, inView, isPlaying, prefersReducedMotion]);

  useImperativeHandle(ref, () => ({
    play: () => {
      if (videoRef.current) {
        videoRef.current.play().then(() => {
          setIsPlaying(true);
        });
      }
    },
    pause: () => {
      if (videoRef.current) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    },
    restart: () => {
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().then(() => {
          setIsPlaying(true);
        });
      }
    },
  }));

  return (
    <Box ref={videoPlayerContainer}>
      <video
        key={roundedWidth}
        data-testid="video"
        ref={setRefs}
        poster={posterUrl}
        muted
        loop
        playsInline
        style={{ aspectRatio }}
        {...(videoLabel
          ? { 'aria-label': videoLabel }
          : { 'aria-hidden': true })}
      >
        {roundedWidth > 0 && inView && (
          <>
            <source src={videoWebm} type="video/webm" />
            <source src={videoMp4} type="video/mp4" />
            This video is not supported by your browser.
          </>
        )}
      </video>
    </Box>
  );
});
