import { generateCloudinaryUrl } from '@stitch-fix/mode-react';

export const getVideoPoster = (videoUrl: string, width = 450) => {
  const extensionPattern = /\.\w{3,4}$/;

  const baseUrl = videoUrl.split('/upload/')[0];
  const mediaPath = videoUrl.split('/upload/')[1];

  const cloudinaryVideoUrl = generateCloudinaryUrl(
    `${baseUrl}/upload`,
    mediaPath,
    {
      f: 'auto',
      q: 'auto',
      w: width || 450,
      // @ts-expect-error - CloudinaryImageTransforms type does not have the
      // start offset qualifier but the function does support it
      so: '0',
    },
  );

  return cloudinaryVideoUrl.replace(extensionPattern, '.jpg');
};

export const getFileName = (url: string) => {
  const sourcePath = url.substring(url.lastIndexOf('/') + 1);
  const fileName = sourcePath.substring(0, sourcePath.indexOf('.'));

  return fileName;
};
