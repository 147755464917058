import React from 'react';
import {
  Color,
  ResponsiveSection,
  type ResponsiveSectionProps,
  Stack,
  type StackProps,
} from '@stitch-fix/mode-react';
import { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';

export type ModuleTitleProps = {
  hAlign?: StackProps['hAlign'];
  gutter?: ResponsiveSectionProps['gutter'];
  titleOrder?: 'major-first' | 'minor-first';
  major?: ContentStackJSONRichText;
  title?: ContentStackJSONRichText;
  subtitle?: ContentStackJSONRichText;
  render?: (children: (JSX.Element | null)[]) => JSX.Element;
  textColor?: Color;
};

export const ModuleTitle = ({
  major,
  title,
  subtitle,
  hAlign = 'center',
  gutter = 'none',
  titleOrder = 'major-first',
  render,
  textColor,
}: ModuleTitleProps) => {
  const textAlignment = hAlign === 'start' ? 'left' : 'center';

  const renderMajorTitle = () => {
    if (!isEmpty(major?.children)) {
      return (
        <JsonRteRenderer
          data={major}
          options={{
            color: textColor || 'blue-45',
            textSizeMap: 'display',
            textProps: {
              h2: {
                spacingBottom: false,
                align: textAlignment,
              },
            },
          }}
        />
      );
    }

    return null;
  };

  const renderItems = () => {
    const items: (JSX.Element | null)[] = [];

    if (titleOrder === 'major-first') {
      items.push(renderMajorTitle());
    }

    items.push(
      <JsonRteRenderer
        data={title}
        options={{
          color: textColor || 'blue-45',
          textSizeMap: 'display',
          textProps: {
            h2: {
              spacingBottom: false,
              align: textAlignment,
              setting: 'display-medium',
            },
          },
        }}
      />,
    );

    if (titleOrder === 'minor-first') {
      items.push(renderMajorTitle());
    }

    if (!isEmpty(subtitle?.children)) {
      items.push(
        <JsonRteRenderer
          data={subtitle}
          options={{
            color: 'blue-45',
            textProps: {
              p: {
                spacingBottom: false,
                align: textAlignment,
              },
            },
          }}
        />,
      );
    }

    return items.map(
      // eslint-disable-next-line react/no-array-index-key
      (item, index) => item && React.cloneElement(item, { key: index }),
    );
  };

  if (render) {
    return render(renderItems());
  }

  return (
    <ResponsiveSection
      mx={hAlign === 'start' ? 0 : 'auto'}
      mb={2.5}
      gutter={gutter}
      width="medium"
    >
      <Stack hAlign={hAlign} spacing={0.5} data-testid="title-container">
        {renderItems()}
      </Stack>
    </ResponsiveSection>
  );
};
