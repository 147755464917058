import { global_seo_data } from '../../utils/requests/contentstack/contentStackZodSchema';
import type { SEODataProps } from './SEOData';
import { getCloudinarySourceUrl } from '../../utils/getCloudinaryItem/getCloudinaryItem';

export const seoDataSchema = global_seo_data.transform<SEODataProps>(data => ({
  bingValidation: data.bing_validation,
  canonicalUrl: data.canonical_url,
  description: data.meta_description,
  googleValidation: data.google_validation,
  openGraphDescription: data.open_graph_description,
  openGraphImageUrl: getCloudinarySourceUrl(data.open_graph_image),
  openGraphTitle: data.open_graph_title,
  robots: data.meta_robots,
  title: data.meta_title,
}));
