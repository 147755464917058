import React from 'react';
import classNames from 'classnames';
import { Box, Text } from '@stitch-fix/mode-react';
import {
  CloudinaryVideoPlayer,
  type CloudinaryVideoPlayerProps,
} from '../CloudinaryVideoPlayer';
import { isNonEmptyString } from '../../utils/helpers/isNonEmptyString';
import styles from './style.module.scss';

export type VideoSlideData = {
  label?: string;
  href?: string;
  video: CloudinaryVideoPlayerProps;
};

export interface VideoSlideProps extends VideoSlideData {
  video: CloudinaryVideoPlayerProps;
}

export const VideoSlide = ({ video, label, href }: VideoSlideProps) => {
  const hasLabel = isNonEmptyString(label);
  const hasLink = isNonEmptyString(href);
  const containerProps = hasLink
    ? ({ as: 'a', href } as const)
    : ({ as: 'div' } as const);

  return (
    <Box
      className={classNames(styles.slideContainer, {
        [styles.hasLink]: hasLink,
      })}
    >
      <CloudinaryVideoPlayer
        videoDescription={video.videoDescription}
        videoUrl={video.videoUrl}
        captions={video.captions}
        playPauseButtonSize="small"
        playPauseButtonAlignment="left"
        allowAutoPlay={false}
        muted={false}
      />
      {hasLabel && (
        <Box className={styles.labelContainer}>
          <Box bgColor="white" py={0.875} px={1}>
            <Text setting="body-small-fixed" align="center" as="span">
              <strong>{label}</strong>
            </Text>
          </Box>
        </Box>
      )}
      <Box
        className={classNames(styles.videoTextContent, {
          [styles.interactiveLink]: hasLink,
        })}
        {...containerProps}
      >
        {hasLabel && (
          <Box className={styles.labelContainer}>
            <Box bgColor="white" py={0.875} px={1}>
              <Text setting="body-small-fixed" align="center" as="span">
                <strong>{label}</strong>
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
