import { useEffect, useRef, useState } from 'react';
import { browserLogger } from '../../utils/loggers/browser';

const waitForCloudinaryScriptLoad = () => {
  const timeout = 10000;
  const expire = Date.now() + timeout;

  return new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line consistent-return
    const isScriptLoaded = () => {
      // if the script hasn't loaded after 10 seconds, reject the promise
      if (Date.now() > expire) {
        return reject(new Error('Cloudinary player script failed to load.'));
      }

      // if cloudinary is defined on the window object, resolve the promise
      if (typeof window.cloudinary !== 'undefined') {
        return resolve();
      }

      // otherwise queue up another check
      window.requestAnimationFrame(isScriptLoaded);
    };

    isScriptLoaded();
  });
};

export const useLoadPlayerScript = () => {
  const [isPlayerScriptLoaded, setIsPlayerScriptLoaded] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    // store isMounted variable to use in cleanup function to prevent setting
    // state if the component is unmounted
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const onScriptReady = async () => {
    try {
      // since onReady can be called before the script is fully loaded, we
      // need to wait until cloudinary is defined on the window object
      await waitForCloudinaryScriptLoad();

      if (isMounted.current) {
        setIsPlayerScriptLoaded(true);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        browserLogger({
          level: 'error',
          team: 'client-onboarding',
          message: 'Cloudinary video player useLoadPlayerScript error',
          context: {
            error,
          },
        });
      }
    }
  };

  return {
    isPlayerScriptLoaded,
    onScriptReady,
  };
};
