import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';
import {
  CloudinaryImageWrapper,
  type CloudinaryImageWrapperProps,
} from '../../../../components/CloudinaryImageWrapper';
import styles from './style.module.scss';

export interface DynamicAdsImageProps {
  onView?: () => void;
  image: Pick<CloudinaryImageWrapperProps, 'src' | 'alt'>;
  aspectRatio: CloudinaryImageWrapperProps['sources'];
}

export const DynamicAdsImage = ({
  onView,
  image,
  aspectRatio,
}: DynamicAdsImageProps) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
    onChange: isInView => isInView && onView?.(),
  });

  return (
    <div className={styles.imageContainer}>
      <div
        data-testid="gallery-image"
        className={classNames(styles.image, {
          [styles.inView]: inView,
        })}
        ref={ref}
      >
        <CloudinaryImageWrapper
          alt={image.alt}
          src={image.src}
          sources={aspectRatio}
          lazy
          convertToCloudFront={false}
          transforms={{
            c: 'pad',
            e: ['trim:10', 'replace_color:f2f3f4:300:ffffff'],
            // @ts-expect-error border isn't a supported transform in ModeReact
            bo: '30px_solid_rgb:f2f3f4',
          }}
        />
      </div>
    </div>
  );
};
