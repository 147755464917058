/**
 * mode's media querie names are based on maxWidth, while most of ours are based
 * on minWidth so there is a little bit of translation to align with how our
 * styles are written.
 *
 * based on https://github.com/stitchfix/mode-react/blob/2b65c55361ebddbf7ecf727865d107aadb8daa6f/src/_internal/screenSizes.ts#L70-L76
 */
export const MODE_MIN_WIDTH = {
  sm: 0,
  md: 560,
  lg: 900,
  xl: 1140,
  xxl: 1730,
} as const;

/**
 * mode's legacy media query names work as described above, but the values are
 * at different breakpoints. Most of these breakpoints are used by the deprecated
 * components on the landing-pages.
 *
 * based on https://github.com/stitchfix/web-frontend/blob/8a3ebc7900bafb1c60fb5ccb38f37dc6441c9a83/packages/mode-style-system/src/style-system/utils/media-queries.scss#L14-L18
 */
export const MODE_LEGACY_MIN_WIDTH = {
  xxs: 360,
  xs: 480,
  sm: 768,
  md: 993,
  lg: 1200,
} as const;
