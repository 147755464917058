import React from 'react';
import { Box, ResponsiveSection } from '@stitch-fix/mode-react';
import { ModuleWrapper, type ModuleWrapperProps } from '../ModuleWrapper';
import { StackedMediaSlide } from './StackedMediaSlide';
import { FullBleedMediaSlide } from './FullBleedMediaSlide';
import styles from './style.module.scss';
import { CarouselWithInstaStories } from '../CarouselWithInstaStories';
import { CloudinaryImageFields } from '../CloudinaryImageWrapper';
import { ContentStackJSONRichText } from '../../../scripts/schemaGenerator/fields/rte';
import { CloudinaryVideoProps } from '../CloudinaryVideo/CloudinaryVideo';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';

export type MediaItemType =
  | (CloudinaryVideoProps & { duration?: number })
  | (CloudinaryImageFields & { duration?: number });

export type ItemsData = {
  media: { mobile: MediaItemType; desktop: MediaItemType };
  content: ContentStackJSONRichText;
  type: 'full-bleed' | 'media-stacked';
};

export type MediaGalleryProps = {
  items: ItemsData[];
  backgroundColor: 'gray-99';
} & Omit<ModuleWrapperProps, 'children' | 'alignment'>;

export const MediaGallery = ({
  moduleTitle,
  items,
  moduleFooter,
  backgroundColor,
  verticalSpacing,
}: MediaGalleryProps & ModularComponentProps) => {
  return (
    <Box bgColor={backgroundColor} mt={verticalSpacing}>
      <ResponsiveSection width="xlarge" data-testid="InteractiveCarousel">
        <ModuleWrapper
          alignment="center"
          moduleTitle={moduleTitle}
          moduleFooter={moduleFooter}
          includePadding
        >
          <Box className={styles.carouselContainer}>
            <CarouselWithInstaStories
              slideWidth="433px"
              slides={items.map(slide => {
                const { media, content, type } = slide;
                const durationMs =
                  'videoUrl' in slide.media.mobile
                    ? (media.mobile.duration ?? 10) * 1000
                    : 5000;

                return {
                  durationMs,
                  ui:
                    type === 'full-bleed' ? (
                      <FullBleedMediaSlide
                        key={content?.uid}
                        media={{
                          mobile: media.mobile as CloudinaryVideoProps,
                          desktop: media.desktop as CloudinaryVideoProps,
                        }}
                        content={content}
                      />
                    ) : (
                      <StackedMediaSlide
                        key={content?.uid}
                        media={{
                          mobile: media.mobile as CloudinaryImageFields,
                          desktop: media.desktop as CloudinaryImageFields,
                        }}
                        content={content}
                      />
                    ),
                };
              })}
            />
          </Box>
        </ModuleWrapper>
      </ResponsiveSection>
    </Box>
  );
};
