import React, { useEffect, useRef } from 'react';
import { Box, useBelowBreakpoint, useMedia } from '@stitch-fix/mode-react';
import { useInView } from 'react-intersection-observer';
import styles from './style.module.scss';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ContentStackJSONRichText } from '../../../scripts/schemaGenerator/fields/rte';
import {
  CloudinaryVideoPlayer,
  type CloudinaryVideoPlayerHandle,
} from '../CloudinaryVideoPlayer';

export interface FullBleedMediaSlideProps {
  media: { mobile: { videoUrl: string }; desktop: { videoUrl: string } };
  content: ContentStackJSONRichText;
}

export const FullBleedMediaSlide = ({
  media,
  content,
}: FullBleedMediaSlideProps) => {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion)');
  const [inViewRef, inView] = useInView({ threshold: 0.5 });
  const isMobile = useBelowBreakpoint(900);
  const videoRef = useRef<CloudinaryVideoPlayerHandle>(null);

  const videoUrl = isMobile ? media.mobile.videoUrl : media.desktop.videoUrl;

  useEffect(() => {
    if (!isMobile || prefersReducedMotion) {
      return;
    }

    if (inView) {
      videoRef.current?.restart();
    } else {
      videoRef.current?.pause();
    }
  }, [isMobile, inView, prefersReducedMotion]);

  return (
    <Box className={styles.videoSlideContainer} ref={inViewRef}>
      <CloudinaryVideoPlayer
        ref={videoRef}
        videoUrl={videoUrl}
        playPauseButtonSize="none"
      />
      <Box className={styles.videoLabelContainer}>
        <JsonRteRenderer
          options={{
            color: 'white',
            textSizeMap: 'display',
            textProps: {
              h2: {
                mb: 0,
              },
              h3: {
                mb: 0,
              },
              h4: {
                mb: 0,
              },
              h5: {
                mb: 0,
              },
              h6: {
                mb: 0,
              },
              p: {
                mb: 0,
              },
            },
          }}
          data={content}
        />
      </Box>
    </Box>
  );
};
