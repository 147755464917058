import { Box } from '@stitch-fix/mode-react';
import classNames from 'classnames';
import type { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import {
  CloudinaryImageWrapper,
  type CloudinaryImageFields,
} from '../CloudinaryImageWrapper';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';
import styles from './styles.module.scss';

export type Theme = {
  backgroundColor: 'mint-60' | 'blue-45' | 'gray-99';
  textColor: 'mint-60' | 'blue-45';
};

export type DisplaySplitProps = {
  text: ContentStackJSONRichText;
  media: CloudinaryImageFields;
  backgroundColor: Theme['backgroundColor'];
  textColor: Theme['textColor'];
  contentOrder?: 'textFirst' | 'mediaFirst';
};

export const DisplaySplit = ({
  text,
  media,
  backgroundColor,
  textColor,
  contentOrder = 'mediaFirst',
  verticalSpacing,
}: DisplaySplitProps & ModularComponentProps) => {
  return (
    <Box bgColor={backgroundColor} mt={verticalSpacing}>
      <Box className={styles.container} data-testid="display-split-container">
        <Box className={styles.media}>
          <CloudinaryImageWrapper
            alt={media.alt}
            src={media.src}
            sources={{
              sm: {
                w: 377,
              },
              md: {
                w: 500,
              },
              lg: {
                w: 650,
              },
            }}
            imageProps={{
              loading: 'lazy',
            }}
          />
        </Box>
        <Box
          className={classNames(styles.text, {
            [styles.textFirst]: contentOrder === 'textFirst',
          })}
        >
          <JsonRteRenderer
            data={text}
            options={{
              textSizeMap: 'display',
              color: textColor,
              textProps: {
                p: {
                  setting: 'body-large',
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
