import { S } from '@mobily/ts-belt';
import {
  Box,
  Inline,
  ResponsiveSection,
  Stack,
  useAtBreakpoint,
} from '@stitch-fix/mode-react';
import { m } from 'framer-motion';
import classNames from 'classnames';
import type { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import {
  type CloudinaryImageFields,
  CloudinaryImageWrapper,
} from '../CloudinaryImageWrapper';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ModuleFooter, type ModuleFooterProps } from '../ModuleFooter';
import { ModuleTitle, type ModuleTitleProps } from '../ModuleTitle';
import styles from './styles.module.scss';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';
import { MODE_MIN_WIDTH } from '../../utils/values/mediaQueries';
import { CloudinaryVideo } from '../CloudinaryVideo/CloudinaryVideo';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';

export type DisplayPanelProps = {
  moduleTitle: ModuleTitleProps;
  moduleFooter: ModuleFooterProps;
  media: CloudinaryImageFields;
  text: ContentStackJSONRichText;
  attribution?: ContentStackJSONRichText;
  avatarImage?: CloudinaryImageFields;
  contentOrder?: 'textFirst' | 'mediaFirst';
  backgroundColor: 'gray-99';
};

export const DisplayPanel = ({
  moduleTitle,
  moduleFooter,
  media,
  text,
  avatarImage,
  attribution,
  contentOrder = 'mediaFirst',
  backgroundColor,
  verticalSpacing,
}: DisplayPanelProps & ModularComponentProps) => {
  const displayEmbeddedModuleWrapper = useAtBreakpoint(900);

  const shouldRenderModuleTitle =
    !isEmpty(moduleTitle.title?.children) &&
    !isEmpty(moduleTitle.major?.children);

  const shouldRenderModuleFooter =
    S.isNotEmpty(moduleFooter.link.href) &&
    S.isNotEmpty(moduleFooter.link.title);

  const shouldRenderAvatar =
    avatarImage?.src && !isEmpty(attribution?.children);

  const isVideo = media.src.includes('.mp4');

  return (
    <Box
      as="section"
      bgColor={backgroundColor}
      pt={4}
      pb={{ sm: 0, lg: 4 }}
      mt={verticalSpacing}
    >
      <ResponsiveSection width="xlarge" gutter="none">
        {!displayEmbeddedModuleWrapper && shouldRenderModuleTitle && (
          <ModuleTitle hAlign="center" {...moduleTitle} />
        )}
        <Box className={styles.container}>
          <Box
            className={classNames(styles.media, {
              [styles.textFirst]: contentOrder === 'textFirst',
            })}
          >
            <m.div
              initial={{ scale: 1 }}
              viewport={{ once: true, amount: 0.5 }}
              whileInView={{ scale: 1.2 }}
              style={{
                transformOrigin: 'top center',
              }}
              transition={{
                duration: 0.8,
                ease: 'easeInOut',
              }}
            >
              {isVideo ? (
                <CloudinaryVideo videoUrl={media.src} videoLabel={media.alt} />
              ) : (
                <CloudinaryImageWrapper
                  alt={media.alt}
                  src={media.src}
                  sources={{
                    sm: {
                      w: 377,
                    },
                    md: {
                      w: 700,
                    },
                    lg: {
                      w: 542,
                    },
                  }}
                  sizes={[
                    { minWidth: 0, fluidWidth: 100 },
                    {
                      minWidth: MODE_MIN_WIDTH.md,
                      fluidWidth: 50,
                    },
                  ]}
                  imageProps={{
                    loading: 'lazy',
                  }}
                  cloudinaryAsset={media.cloudinaryAsset}
                />
              )}
            </m.div>
          </Box>
          <Box
            className={classNames(styles.content, {
              [styles.textFirst]: contentOrder === 'textFirst',
            })}
          >
            {displayEmbeddedModuleWrapper && shouldRenderModuleTitle && (
              <Box mb={2.5}>
                <ModuleTitle
                  hAlign="start"
                  render={items => {
                    return (
                      <Stack
                        hAlign="start"
                        spacing={0.5}
                        data-testid="title-container"
                      >
                        {items}
                      </Stack>
                    );
                  }}
                  {...moduleTitle}
                />
              </Box>
            )}
            <JsonRteRenderer
              data={text}
              options={{
                color: 'blue-45',
                textSizeMap: 'display',
                textProps: {
                  p: {
                    setting: 'body-large',
                  },
                },
              }}
            />
            {shouldRenderAvatar && (
              <Inline spacing="1rem" mt={2} vAlign="center">
                {avatarImage.src && (
                  <Box className={styles.avatar}>
                    <CloudinaryImageWrapper
                      alt={avatarImage.alt}
                      src={avatarImage.src}
                      sources={{
                        sm: {
                          w: 64,
                        },
                      }}
                      imageProps={{
                        loading: 'lazy',
                      }}
                      cloudinaryAsset={avatarImage.cloudinaryAsset}
                      sizes={[{ minWidth: 0, staticWidth: 64 }]}
                    />
                  </Box>
                )}

                {!isEmpty(attribution?.children) && (
                  <JsonRteRenderer
                    data={attribution}
                    options={{
                      textSizeMap: 'display',
                      textProps: {
                        p: {
                          setting: 'body-large',
                          color: 'blue-45',
                          spacingBottom: false,
                        },
                      },
                    }}
                  />
                )}
              </Inline>
            )}

            {displayEmbeddedModuleWrapper && shouldRenderModuleFooter && (
              <ModuleFooter {...moduleFooter} />
            )}
          </Box>
        </Box>
      </ResponsiveSection>
    </Box>
  );
};
