import classNames from 'classnames';
import { Box, Grid, ResponsiveSection, Text } from '@stitch-fix/mode-react';
import {
  CloudinaryImageFields,
  CloudinaryImageWrapper,
} from '../CloudinaryImageWrapper';
import { ModuleWrapper, ModuleWrapperProps } from '../ModuleWrapper';
import styles from './styles.module.scss';
import { isNonEmptyString } from '../../utils/helpers';
import { MODE_MIN_WIDTH } from '../../utils/values/mediaQueries';

type GridItem = {
  image: CloudinaryImageFields;
  label: string;
  href: string;
};

export type InteractiveGridProps = {
  items: GridItem[];
} & Omit<ModuleWrapperProps, 'children'>;

export const InteractiveGrid = ({
  alignment,
  items,
  moduleTitle,
  moduleFooter,
}: InteractiveGridProps) => {
  const shouldRenderLabel = (item: GridItem) => {
    return isNonEmptyString(item.label);
  };

  const shouldRenderLink = (item: GridItem) => {
    return isNonEmptyString(item.href);
  };

  return (
    <ResponsiveSection width="xxlarge" gutter="none">
      <ModuleWrapper
        alignment={alignment}
        moduleTitle={moduleTitle}
        moduleFooter={moduleFooter}
      >
        <Grid
          columns={{ sm: 2, md: 3 }}
          spacing={{ sm: 'tight', lg: 'standard' }}
        >
          {items.map((item, index) => {
            const image = item.image.src ? (
              <Box className={styles.imageContainer}>
                <CloudinaryImageWrapper
                  {...item.image}
                  sources={{
                    sm: {
                      w: 550,
                    },
                  }}
                  cloudinaryAsset={item.image.cloudinaryAsset}
                  sizes={[
                    { minWidth: 0, fluidWidth: 50 },
                    { minWidth: MODE_MIN_WIDTH.md, fluidWidth: 100 / 3 },
                  ]}
                  maxContainerWidth="md"
                  imageProps={{ loading: 'lazy' }}
                />
              </Box>
            ) : null;

            const label = (
              <Box className={styles.labelContainer}>
                <Box bgColor="white" py={0.875} px={1}>
                  <Text setting="body-small" align="center" as="span">
                    <strong>{item.label}</strong>
                  </Text>
                </Box>
              </Box>
            );

            const gridItemContents = (
              <Box>
                {image}
                {shouldRenderLabel(item) && label}
              </Box>
            );

            return shouldRenderLink(item) ? (
              <Box
                className={classNames(styles.gridItemContainer, styles.hasLink)}
                as="a"
                href={item.href}
                // List is static and won't change once rendered
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {gridItemContents}
              </Box>
            ) : (
              <Box
                className={styles.gridItemContainer}
                // List is static and won't change once rendered
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                {gridItemContents}
              </Box>
            );
          })}
        </Grid>
      </ModuleWrapper>
    </ResponsiveSection>
  );
};
