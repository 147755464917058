import {
  Box,
  Grid,
  ResponsiveSection,
  useScreenSize,
} from '@stitch-fix/mode-react';
import { easeInOut, m } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { ModuleWrapper, type ModuleWrapperProps } from '../ModuleWrapper';
import { CloudinaryImageFields } from '../CloudinaryImageWrapper';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { CloudinaryImageV2 } from '../CSCloudinaryAsset';
import { ModularComponentProps } from '../ModularPageWrapper/ModularPageWrapper';

type AnimatedGridItem = {
  label: ContentStackJSONRichText;
  description: ContentStackJSONRichText;
  topImage: CloudinaryImageFields;
  bottomImage: CloudinaryImageFields;
};

export type AnimatedGridProps = {
  items: AnimatedGridItem[];
  backgroundColor: 'mint-60';
} & Omit<ModuleWrapperProps, 'children'>;

const GridItem = ({
  item,
  index,
}: {
  item: AnimatedGridItem;
  index: number;
}) => {
  const screensize = useScreenSize();
  const animationDuration = screensize === 'sm' ? 0.8 : 0.33;
  const [ref, inView] = useInView({ triggerOnce: true });
  const delay = index * 0.2;

  const gridItemAnimationProps = {
    initial: 'hidden',
    animate: inView ? 'show' : 'hidden',
    transition: { delay, duration: animationDuration },
    variants: {
      hidden: { opacity: 0 },
      show: { opacity: 1 },
    },
  };

  const topImageAnimation = {
    hidden: { opacity: 0, scale: 0.65, y: 16 },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { duration: animationDuration, ease: easeInOut },
    },
  };
  const bottomImageAnimation = {
    hidden: { opacity: 0, scale: 0.65, y: -16 },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: { duration: animationDuration, ease: easeInOut },
    },
  };
  const textAnimation = {
    hidden: { opacity: 0, y: 40 },
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: animationDuration, ease: easeInOut },
    },
  };

  const getImageProps = (image: CloudinaryImageFields) => {
    if (image.cloudinaryAsset) {
      const imageExtension = image.cloudinaryAsset.path.split('.').pop();

      return imageExtension === 'svg'
        ? {
            style: {
              width: image.cloudinaryAsset.width,
              height: image.cloudinaryAsset.height,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          }
        : {};
    }

    return {};
  };

  const topImage = item.topImage.cloudinaryAsset ? (
    <CloudinaryImageV2
      alt={item.topImage.alt}
      cloudinaryAsset={item.topImage.cloudinaryAsset}
      imageProps={getImageProps(item.topImage)}
    />
  ) : null;

  const bottomImage = item.bottomImage.cloudinaryAsset ? (
    <CloudinaryImageV2
      alt={item.bottomImage.alt}
      cloudinaryAsset={item.bottomImage.cloudinaryAsset}
      imageProps={getImageProps(item.bottomImage)}
    />
  ) : null;

  return (
    <m.div key={index} ref={ref} {...gridItemAnimationProps}>
      <m.div variants={topImageAnimation}>{topImage}</m.div>
      <m.div variants={textAnimation}>
        <Box pt={1} pb={1}>
          <JsonRteRenderer
            data={item.label}
            options={{
              textSizeMap: 'display',
              textProps: {
                h3: {
                  color: 'blue-45',
                  setting: 'display-medium',
                  mb: screensize === 'sm' ? 0.5 : 1,
                },
              },
            }}
          />
          <JsonRteRenderer
            data={item.description}
            options={{
              textSizeMap: 'display',
              textProps: {
                p: {
                  color: 'blue-45',
                  setting: 'body-medium',
                  mb: 0,
                },
              },
            }}
          />
        </Box>
      </m.div>
      <m.div variants={bottomImageAnimation}>{bottomImage}</m.div>
    </m.div>
  );
};

export const AnimatedGrid = ({
  moduleTitle,
  moduleFooter,
  alignment,
  items,
  backgroundColor,
  verticalSpacing,
}: AnimatedGridProps & ModularComponentProps) => {
  return (
    <Box as="section" bgColor={backgroundColor} mt={verticalSpacing}>
      <ResponsiveSection width="xxlarge">
        <ModuleWrapper
          alignment={alignment}
          moduleFooter={moduleFooter}
          moduleTitle={moduleTitle}
          includePadding
        >
          <Grid
            columns={{ sm: 1, md: 3, lg: 3 }}
            spacing={[5, 2.5]}
            data-testid="grid"
          >
            {items.map((item, index) => (
              <GridItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                item={item}
                index={index}
              />
            ))}
          </Grid>
        </ModuleWrapper>
      </ResponsiveSection>
    </Box>
  );
};
