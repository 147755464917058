import { S } from '@mobily/ts-belt';
import { Box, Color } from '@stitch-fix/mode-react';
import { ModuleFooter, type ModuleFooterProps } from '../ModuleFooter';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';
import { ModuleTitle, type ModuleTitleProps } from '../ModuleTitle';

export type ModuleWrapperProps = {
  alignment: 'left' | 'center';
  moduleTitle: Pick<
    ModuleTitleProps,
    'major' | 'title' | 'subtitle' | 'titleOrder'
  >;
  moduleFooter: Pick<
    ModuleFooterProps,
    'link' | 'hideOnMobile' | 'buttonColor'
  >;
  children: React.ReactNode;
  includePadding?: boolean;
  textColor?: Color;
};

export const ModuleWrapper = ({
  alignment,
  moduleTitle,
  moduleFooter,
  children,
  includePadding = false,
  textColor,
}: ModuleWrapperProps) => {
  const hAlign = alignment === 'left' ? 'start' : 'center';

  const shouldRenderModuleTitle =
    !isEmpty(moduleTitle.major?.children) ||
    !isEmpty(moduleTitle.title?.children) ||
    !isEmpty(moduleTitle.subtitle?.children);

  const shouldRenderModuleFooter =
    S.isNotEmpty(moduleFooter.link.href) &&
    S.isNotEmpty(moduleFooter.link.title);

  return (
    <Box pt={includePadding ? 4 : 0} pb={includePadding ? 4 : 0}>
      {shouldRenderModuleTitle && (
        <ModuleTitle
          hAlign={hAlign}
          major={moduleTitle.major}
          title={moduleTitle.title}
          subtitle={moduleTitle.subtitle}
          titleOrder={moduleTitle.titleOrder}
          textColor={textColor}
        />
      )}
      {children}
      {shouldRenderModuleFooter && (
        <ModuleFooter
          hAlign={hAlign}
          link={moduleFooter.link}
          hideOnMobile={moduleFooter.hideOnMobile}
          buttonColor={moduleFooter.buttonColor}
        />
      )}
    </Box>
  );
};
