import NukaCarousel from '@stitch-fix/nuka-carousel';
import {
  Box,
  Carousel,
  useBelowBreakpoint,
  type CarouselProps,
} from '@stitch-fix/mode-react';
import { useInView } from 'react-intersection-observer';
import { ProgressIndicator } from './ProgressIndicator';
import styles from './styles.module.scss';

type Slide = {
  durationMs?: number;
  ui: JSX.Element;
};

const DesktopCarousel = ({
  slides,
  slideWidth,
}: {
  slides: JSX.Element[];
  slideWidth: CarouselProps['slideWidth'];
}) => (
  <Box maxWidth="1084px" data-testid="desktop-carousel">
    <Carousel ariaLabel="Carousel" slideWidth={slideWidth}>
      {slides}
    </Carousel>
  </Box>
);

const MobileCarousel = ({ slides }: { slides: Slide[] }) => {
  const [ref, inView] = useInView();

  return (
    <Box
      maxWidth="644px"
      data-testid="mobile-carousel"
      ref={ref}
      className={styles.mobileCarousel}
    >
      <NukaCarousel
        slidesToShow={1}
        defaultControlsConfig={{
          // allows the progress indicator to stretch the full width of the carousel
          containerClassName: styles.container,
        }}
        renderBottomCenterControls={null}
        renderCenterLeftControls={null}
        renderCenterRightControls={null}
        renderTopCenterControls={props => (
          <ProgressIndicator
            durationMs={slides[props.currentSlide].durationMs}
            isInView={inView}
            {...props}
          />
        )}
      >
        {slides.map(slide => slide.ui)}
      </NukaCarousel>
    </Box>
  );
};

export const CarouselWithInstaStories = ({
  slides,
  slideWidth,
}: {
  slides: Slide[];
  slideWidth: CarouselProps['slideWidth'];
}) => {
  return useBelowBreakpoint(900) ? (
    <MobileCarousel slides={slides} />
  ) : (
    <DesktopCarousel
      slides={slides.map(slide => slide.ui)}
      slideWidth={slideWidth}
    />
  );
};
