import { Box } from '@stitch-fix/mode-react';
import type { ControlProps } from '@stitch-fix/nuka-carousel';
import classNames from 'classnames';
import { AnimatePresence, LazyMotion, m } from 'framer-motion';
import styles from './styles.module.scss';

const AnimatingIndicator = ({
  animate,
  durationMs,
  isInView,
  nextSlide,
}: {
  animate: boolean;
  durationMs: number;
  isInView: boolean;
  nextSlide: () => void;
}) => {
  if (!animate) return null;

  return (
    <LazyMotion
      features={() => import('framer-motion').then(mod => mod.domAnimation)}
    >
      <AnimatePresence>
        <m.div
          className={styles.filled}
          style={{ height: '100%' }}
          initial={{ width: 0 }}
          animate={isInView ? { width: '100%' } : {}}
          transition={{ duration: durationMs / 1000 }}
          onAnimationComplete={isInView ? nextSlide : undefined}
          data-testid="animating-indicator"
        />
      </AnimatePresence>
    </LazyMotion>
  );
};

interface ProgressIndicatorProps
  extends Pick<
    ControlProps,
    'currentSlide' | 'nextSlide' | 'pagingDotsIndices' | 'slideCount'
  > {
  durationMs?: number;
  isInView: boolean;
}

export const ProgressIndicator = ({
  durationMs = 3000,
  currentSlide,
  isInView,
  nextSlide,
  pagingDotsIndices,
  slideCount,
}: ProgressIndicatorProps) => (
  <Box className={styles.indicatorsContainer} as="ul">
    {pagingDotsIndices.map(index => (
      <Box
        as="li"
        style={{ width: `${100 / slideCount}%` }}
        className={classNames(styles.indicator, {
          [styles.filled]: index < currentSlide,
        })}
        key={`indicator-${index}`}
        data-testid="indicator"
      >
        <AnimatingIndicator
          animate={index === currentSlide}
          durationMs={durationMs}
          isInView={isInView}
          nextSlide={nextSlide}
        />
      </Box>
    ))}
  </Box>
);
