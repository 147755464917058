import React from 'react';
import classNames from 'classnames';
import { Box, Text } from '@stitch-fix/mode-react';
import {
  CloudinaryImageFields,
  CloudinaryImageWrapper,
  CloudinaryImageWrapperProps,
} from '../CloudinaryImageWrapper';
import { isNonEmptyString } from '../../utils/helpers/isNonEmptyString';
import styles from './style.module.scss';
import { SizeConfig } from '../CSCloudinaryAsset/utils';

export type ImageSlideData = {
  label?: string;
  href?: string;
  image: CloudinaryImageFields;
  sizes?: SizeConfig[];
};

export interface ImageSlideProps extends ImageSlideData {
  image: CloudinaryImageWrapperProps;
}

export const ImageSlide = ({ image, label, href, sizes }: ImageSlideProps) => {
  const hasLabel = isNonEmptyString(label);
  const hasLink = isNonEmptyString(href);
  const containerProps = hasLink
    ? ({ as: 'a', href } as const)
    : ({ as: 'div' } as const);

  return (
    <Box
      className={classNames(styles.slideContainer, {
        [styles.hasLink]: hasLink,
        [styles.interactiveLink]: hasLink,
      })}
      {...containerProps}
    >
      <Box className={styles.imageContainer}>
        <CloudinaryImageWrapper
          src={image.src}
          alt={image.alt}
          sources={image.sources}
          aspectRatio={image.aspectRatio}
          cloudinaryAsset={image.cloudinaryAsset}
          sizes={sizes}
          imageProps={{ loading: 'lazy' }}
        />
      </Box>
      {hasLabel && (
        <Box className={styles.labelContainer}>
          <Box bgColor="white" py={0.875} px={1}>
            <Text setting="body-small-fixed" align="center" as="span">
              <strong>{label}</strong>
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};
