import {
  Box,
  Carousel,
  ResponsiveSection,
  Stack,
  type CloudinaryImageProps,
  type CarouselProps,
} from '@stitch-fix/mode-react';
import classNames from 'classnames';
import type { ContentStackJSONRichText } from '../../utils/requests/contentstack/contentStackZodSchema';
import { MODE_MIN_WIDTH } from '../../utils/values/mediaQueries';
import {
  type CloudinaryImageFields,
  CloudinaryImageWrapper,
} from '../CloudinaryImageWrapper';
import { CloudinaryVideo } from '../CloudinaryVideo/CloudinaryVideo';
import { SizeConfig } from '../CSCloudinaryAsset/utils';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { isEmpty } from '../JsonRteRenderer/JsonRteRenderer';
import { ModuleWrapper, type ModuleWrapperProps } from '../ModuleWrapper';
import styles from './styles.module.scss';

const getWidth = (width: string): CarouselProps['slideWidth'] => {
  switch (width) {
    case 'large':
      return {
        sm: '80%',
        md: '384px',
        lg: '384px',
        xl: '496px',
        xxl: '496px',
      };
    case 'medium':
      return {
        sm: '60%',
        md: '272px',
        lg: '272px',
        xl: '352px',
        xxl: '352px',
      };
    case 'small':
    default:
      return {
        sm: '40%',
        md: '224px',
        lg: '224px',
        xl: '280px',
        xxl: '280px',
      };
  }
};

const getImageSources = (width: string): CloudinaryImageProps['sources'] => {
  switch (width) {
    case 'large':
      return {
        sm: { w: 435 },
        md: { w: 530 },
        lg: { w: 388 },
        xl: { w: 399 },
      };
    case 'medium':
      return {
        sm: { w: 326 },
        md: { w: 354 },
        lg: { w: 277 },
        xl: { w: 342 },
      };
    case 'small':
    default:
      return {
        sm: { w: 218 },
        md: { w: 222 },
        xl: { w: 274 },
      };
  }
};

type Slide = {
  content: {
    text: ContentStackJSONRichText;
    placement: 'above' | 'below';
  };
  image: CloudinaryImageFields;
};

export type DisplayCarouselProps = {
  slideWidth: 'small' | 'medium' | 'large';
  slides: Slide[];
} & Omit<ModuleWrapperProps, 'children'>;

const sizes = new Map<DisplayCarouselProps['slideWidth'], SizeConfig[]>([
  [
    'small',
    [
      { minWidth: 0, fluidWidth: 35 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 250 },
    ],
  ],
  [
    'medium',
    [
      { minWidth: 0, fluidWidth: 55 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 350 },
    ],
  ],
  [
    'large',
    [
      { minWidth: 0, fluidWidth: 75 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 530 },
      { minWidth: MODE_MIN_WIDTH.md, staticWidth: 390 },
    ],
  ],
]);

export const DisplayCarousel = ({
  moduleTitle,
  moduleFooter,
  slides,
  slideWidth,
  alignment,
}: DisplayCarouselProps) => {
  return (
    <ResponsiveSection width="xxlarge">
      <ModuleWrapper
        alignment={alignment}
        moduleTitle={moduleTitle}
        moduleFooter={moduleFooter}
      >
        <Box
          className={classNames(styles.carouselContainer, {
            [styles.leftAlign]: alignment === 'left',
            [styles.centerAlign]: alignment === 'center',
          })}
        >
          <Carousel slideWidth={getWidth(slideWidth)} ariaLabel="Carousel">
            {slides.map((slide, index) => {
              const jsonRteRenderer = !isEmpty(slide.content.text?.children) ? (
                <Box
                  // List is static and won't change once rendered
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  px={0.5}
                >
                  <JsonRteRenderer
                    data={slide.content.text}
                    options={{
                      textProps: { p: { spacingBottom: false } },
                    }}
                  />
                </Box>
              ) : null;

              let media = null;

              if (slide.image.src.includes('.mp4')) {
                media = (
                  <CloudinaryVideo
                    key={slide.image.src}
                    videoUrl={slide.image.src}
                    videoLabel={slide.image.alt}
                  />
                );
              } else if (slide.image.src.length > 0) {
                media = (
                  <CloudinaryImageWrapper
                    key={slide.image.src}
                    {...slide.image}
                    sources={getImageSources(slideWidth)}
                    cloudinaryAsset={slide.image.cloudinaryAsset}
                    sizes={sizes.get(slideWidth)}
                    imageProps={{ loading: 'lazy' }}
                  />
                );
              }

              return (
                // List is static and won't change once rendered
                // eslint-disable-next-line react/no-array-index-key
                <Stack spacing={1} key={index}>
                  {slide.content.placement === 'above'
                    ? [jsonRteRenderer, media]
                    : [media, jsonRteRenderer]}
                </Stack>
              );
            })}
          </Carousel>
        </Box>
      </ModuleWrapper>
    </ResponsiveSection>
  );
};
