import { Box, Button, Stack, type StackProps } from '@stitch-fix/mode-react';
import classNames from 'classnames';
import { z } from 'zod';
import { linkField } from '../../../scripts/schemaGenerator/fields/linkField';
import styles from './styles.module.scss';

export const buttonColorToButtonVariant = (buttonColor?: string) => {
  switch (buttonColor) {
    case 'mint':
      return 'filled-statement-large';
    case 'navy':
      return 'filled-staple-large';
    case 'citrus':
    default:
      return 'filled-accent-large';
  }
};

export type ModuleFooterProps = {
  link: z.infer<typeof linkField>;
  hideOnMobile: boolean;
  buttonColor?: string;
} & Partial<Pick<StackProps, 'hAlign'>>;

export const ModuleFooter = ({
  link: { href, title },
  hAlign = 'start',
  hideOnMobile,
  buttonColor,
}: ModuleFooterProps) => {
  return (
    <Box
      className={classNames({
        [styles.hideOnMobile]: hideOnMobile,
      })}
    >
      <Stack hAlign={hAlign} spacing={1} mt={2.5}>
        <Button
          as="a"
          href={href}
          variant={buttonColorToButtonVariant(buttonColor)}
        >
          {title}
        </Button>
      </Stack>
    </Box>
  );
};
