import { ResponsiveSection, Stack } from '@stitch-fix/mode-react';
import { LazyMotion } from 'framer-motion';
import { ResponsiveSizeValue } from '@stitch-fix/mode-react/_types/_internal/styling';
import { AccordionGroup, type AccordionGroupProps } from '../AccordionGroup';
import { DisplayCarousel, type DisplayCarouselProps } from '../DisplayCarousel';
import { DisplayGrid, type DisplayGridProps } from '../DisplayGrid';
import { DisplayText, type DisplayTextProps } from '../DisplayText';
import { InteractiveGrid, type InteractiveGridProps } from '../InteractiveGrid';
import { StickyCta, type StickyCtaProps } from '../StickyCta';
import { Layout, type LayoutProps } from '../Layout';
import { SEOData, type SEODataProps } from '../SEOData';
import { Hero, type HeroProps } from '../Hero';
import {
  type InteractiveCarouselProps,
  InteractiveCarousel,
} from '../InteractiveCarousel/InteractiveCarousel';
import {
  StickyCtaContextProvider,
  useStickyCtaContext,
} from '../StickyCta/useStickyCtaContext';
import {
  DynamicAdsHero,
  type DynamicAdsHeroProps,
} from '../../features/dynamicAds/components/DynamicAdsHero/DynamicAdsHero';
import { GalleryHero, type GalleryHeroProps } from '../GalleryHero/GalleryHero';
import {
  DisplaySplit,
  type DisplaySplitProps,
} from '../DisplaySplit/DisplaySplit';
import {
  AnimatedGrid,
  type AnimatedGridProps,
} from '../AnimatedGrid/AnimatedGrid';
import {
  DisplayPanel,
  type DisplayPanelProps,
} from '../DisplayPanel/DisplayPanel';
import {
  MediaGallery,
  type MediaGalleryProps,
} from '../MediaGallery/MediaGallery';
import { getVerticalSpacing } from './getVerticalSpacing/getVerticalSpacing';

export type ModularComponentProps = {
  backgroundColor?: string;
  verticalSpacing?: ResponsiveSizeValue;
};

// Define each module with its specific props type
type ModuleDefinition = {
  mediaGallery: MediaGalleryProps;
  galleryHero: GalleryHeroProps;
  hero: HeroProps;
  dynamicAdsHero: DynamicAdsHeroProps;
  displayGrid: DisplayGridProps;
  displayCarousel: DisplayCarouselProps;
  displaySplit: DisplaySplitProps;
  interactiveGrid: InteractiveGridProps;
  interactiveCarousel: InteractiveCarouselProps;
  displayText: DisplayTextProps;
  accordionGroup: AccordionGroupProps;
  stickyCta: StickyCtaProps;
  animatedGrid: AnimatedGridProps;
  displayPanel: DisplayPanelProps;
};

// Map over ModuleDefinition to include ModularComponentProps in each module's props
export type ModularComponent =
  | {
      [K in keyof ModuleDefinition]: {
        type: K;
        props: ModuleDefinition[K] & ModularComponentProps;
      };
    }[keyof ModuleDefinition]
  | { type: 'notSupported'; props: undefined };

export type ModularPageWrapperProps = {
  seoData: SEODataProps;
  modules: ModularComponent[];
  viewEventTrackingName?: LayoutProps['viewEventTrackingName'];
};

const modularComponentResolver = (module: ModularComponent) => {
  if (module.type === 'hero') {
    return <Hero {...module.props} />;
  }

  if (module.type === 'dynamicAdsHero') {
    return <DynamicAdsHero {...module.props} />;
  }

  if (module.type === 'displayGrid') {
    return <DisplayGrid {...module.props} />;
  }

  if (module.type === 'displayCarousel') {
    return <DisplayCarousel {...module.props} />;
  }

  if (module.type === 'interactiveGrid') {
    return <InteractiveGrid {...module.props} />;
  }
  if (module.type === 'interactiveCarousel') {
    return <InteractiveCarousel {...module.props} />;
  }

  if (module.type === 'displayText') {
    return <DisplayText {...module.props} />;
  }

  if (module.type === 'accordionGroup') {
    return <AccordionGroup {...module.props} />;
  }

  if (module.type === 'stickyCta') {
    return <StickyCta {...module.props} />;
  }

  if (module.type === 'galleryHero') {
    return <GalleryHero {...module.props} />;
  }

  if (module.type === 'displaySplit') {
    return <DisplaySplit {...module.props} />;
  }

  if (module.type === 'animatedGrid') {
    return <AnimatedGrid {...module.props} />;
  }

  if (module.type === 'displayPanel') {
    return <DisplayPanel {...module.props} />;
  }

  if (module.type === 'mediaGallery') {
    return <MediaGallery {...module.props} />;
  }

  return null;
};

export const ModulePageComponents = ({
  modules,
}: {
  modules: ModularComponent[];
}) => {
  const modulesWithSpacing = getVerticalSpacing(modules);

  return (
    <Stack spacing={{ sm: '3rem', lg: '4rem' }}>
      {modulesWithSpacing.map((module, index) => {
        const key = `${module.type}-${index}`;

        return (
          <div key={key} data-testid={module.type}>
            {modularComponentResolver(module)}
          </div>
        );
      })}
    </Stack>
  );
};
const ModulePageRenderer = ({
  seoData,
  modules,
  viewEventTrackingName,
}: Pick<
  ModularPageWrapperProps,
  'modules' | 'seoData' | 'viewEventTrackingName'
>) => {
  const { setStickyContainerTarget } = useStickyCtaContext();

  return (
    <LazyMotion
      features={() => import('framer-motion').then(mod => mod.domAnimation)}
    >
      <Layout
        viewEventTrackingName={viewEventTrackingName}
        footerRef={setStickyContainerTarget}
      >
        <ResponsiveSection
          as="main"
          // setting this id allows the "Skip to page content" link to render from knit
          id="knit-main-content"
          mt="0"
          width={{ sm: 'full-bleed' }}
          gutter="none"
        >
          <SEOData {...seoData} />
          <ModulePageComponents modules={modules} />
        </ResponsiveSection>
      </Layout>
    </LazyMotion>
  );
};

export const ModularPageWrapper = ({
  seoData,
  modules,
  viewEventTrackingName = 'landing',
}: ModularPageWrapperProps) => {
  return (
    <StickyCtaContextProvider>
      <ModulePageRenderer
        seoData={seoData}
        modules={modules}
        viewEventTrackingName={viewEventTrackingName}
      />
    </StickyCtaContextProvider>
  );
};
