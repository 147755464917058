import { ResponsiveSizeValue } from '@stitch-fix/mode-react/_types/_internal/styling';
import { ModularComponent } from '../ModularPageWrapper';

export const getVerticalSpacing = (
  modules: ModularComponent[],
): ModularComponent[] => {
  const modulesWithSpacing = modules.map((currentModule, index, moduleList) => {
    const previousModule: ModularComponent = moduleList[index - 1] ?? undefined;

    const currentModuleBackground: string | undefined =
      currentModule.props?.backgroundColor;
    const previousModuleBackground: string | undefined =
      previousModule?.props?.backgroundColor;
    let verticalSpacing: ResponsiveSizeValue;

    const currentModuleIsHeroModule =
      currentModule?.type === 'hero' || currentModule?.type === 'galleryHero';

    const previousModuleIsHeroModule =
      previousModule?.type === 'hero' || previousModule?.type === 'galleryHero';

    if (index === 1 && currentModuleIsHeroModule) {
      verticalSpacing = { sm: '-3rem', lg: '-4rem' }; // remove stack spacing
    } else if (
      previousModuleIsHeroModule &&
      currentModuleBackground &&
      currentModuleBackground !== 'white'
    ) {
      verticalSpacing = { sm: '-3rem', lg: '-4rem' }; // remove stack spacing
    } else if (
      !previousModuleBackground ||
      !currentModuleBackground ||
      previousModuleBackground === 'white' ||
      currentModuleBackground === 'white'
    ) {
      verticalSpacing = {};
    } else if (currentModuleBackground === previousModuleBackground) {
      verticalSpacing = { sm: '-6rem', lg: '-8rem' }; // remove stack spacing plus collapse extra spacing between modules with same background color
    } else {
      verticalSpacing = { sm: '-3rem', lg: '-4rem' }; // remove stack spacing
    }

    const updatedModule: ModularComponent = {
      ...currentModule,
      props: {
        verticalSpacing,
        ...currentModule.props,
      },
    } as ModularComponent;

    return updatedModule;
  });

  return modulesWithSpacing;
};
