import React from 'react';
import type { ButtonProps } from '@stitch-fix/mode-react';
import { Button } from '@stitch-fix/mode-react';
import { loadTranslations } from '../../i18n';
import translations from './index-translations.json';
import { trackCtaClick } from '../../utils/events';

export interface GetStartedButtonProps extends ButtonProps<'a'> {
  eventLocation: 'hero' | 'nav' | 'page_mid' | 'page_end' | 'style_shuffle_end';
  href?: string;
  copy?: string;
}

const { useTranslation } = loadTranslations(translations, 'getStartedButton');

export const GetStartedButton = ({
  href = '/signup',
  copy,
  eventLocation,
  ...rest
}: GetStartedButtonProps) => {
  const { t } = useTranslation();
  const copyTitle = copy ?? t('copy');
  const sendCtaClickTrack = () => {
    trackCtaClick({
      actionDetail: 'get_started_cta_click',
      eventContext: {
        get_started_cta: { copy: copyTitle, href, location: eventLocation },
      },
    });
  };

  return (
    <Button as="a" href={href} onClick={() => sendCtaClickTrack()} {...rest}>
      {copyTitle}
    </Button>
  );
};
