import React from 'react';
import { Box } from '@stitch-fix/mode-react';
import { CloudinaryImageFields } from '../CloudinaryImageWrapper';
import styles from './style.module.scss';
import { JsonRteRenderer } from '../JsonRteRenderer';
import { ContentStackJSONRichText } from '../../../scripts/schemaGenerator/fields/rte';
import { CloudinaryPicture } from '../CSCloudinaryAsset/Picture';

export interface StackedMediaSlideProps {
  media: { mobile: CloudinaryImageFields; desktop: CloudinaryImageFields };
  content: ContentStackJSONRichText;
}

export const StackedMediaSlide = ({
  media,
  content,
}: StackedMediaSlideProps) => {
  return (
    <Box className={styles.imageSlideContainer}>
      <CloudinaryPicture
        alt={media?.desktop.alt ?? ''}
        sources={[
          {
            minWidth: 0,
            cloudinaryAsset: media?.mobile.cloudinaryAsset,
            sizes: [{ minWidth: 0, fluidWidth: 100 }],
          },
          {
            minWidth: 900,
            cloudinaryAsset: media?.desktop.cloudinaryAsset,
            sizes: [{ minWidth: 0, staticWidth: 353 }],
          },
        ]}
        imageProps={{ loading: 'lazy' }}
      />
      <Box className={styles.imageLabelContainer}>
        <JsonRteRenderer
          options={{
            textSizeMap: 'display',
            color: 'blue-45',
            textProps: {
              h2: {
                mb: 0,
              },
              h3: {
                mb: 0,
              },
              h4: {
                mb: 0,
              },
              h5: {
                mb: 0,
              },
              h6: {
                mb: 0,
              },
              p: {
                mb: 0,
              },
            },
          }}
          data={content}
        />
      </Box>
    </Box>
  );
};
