import { type ImgHTMLAttributes, type HTMLAttributes } from 'react';
import {
  type BasicCloudinaryAsset,
  type SizeConfig,
  getPictureSrc,
  getSizes,
  getPictureSrcSet,
} from './utils';

type Source<TMinWidth extends number> = {
  minWidth: TMinWidth;
  cloudinaryAsset?: BasicCloudinaryAsset;
  sizes?: SizeConfig[];
};

export type CloudinaryPictureProps = {
  alt: string;
  /** Array of Cloudinary assets that should be displayed at the specified min-width in pixels, e.g.,
   * `[{ minWidth: 0, cloudinaryAsset: portraitImg }, { minWidth: 900, cloudinaryAsset: landscapeImg }]`
   *
   * If an image is hidden at a given breakpoint, you can omit the `cloudinaryAsset` field
   * to prevent the browser from unnecessarily requesting an image at that breakpoint.
   *
   * ```tsx
   * /// image container that has display none on desktop
   * [{ minWidth: 0, cloudinaryAsset: portraitImg }, { minWidth: 900 }]
   * ///
   * /// image container that has display none on mobile
   * [{ minWidth: 0 }, { minWidth: 900, cloudinaryAsset: landscapeImg }]
   * ```
   */
  sources: [Source<0>, ...Source<number>[]];
  /**
   * Max container width in pixels.
   *
   * Not all layouts are full bleed, they have maximum widths on desktop.
   * To avoid serving larger images than necessary, we can use the `maxContainerWidth`
   * in conjunction the view width (as a percentage) to calculate the maximum width of an image
   * in pixels.
   */
  maxContainerWidth?: number;
  /** Props passed down to the `<img>` element */
  imageProps?: Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'srcset' | 'alt'
  >;
  /** Props passed down to the `<picture>` element */
  pictureProps?: Record<string, unknown> & HTMLAttributes<HTMLElement>;
};

/**
 * Use `CloudinaryPicture` whenever you need to show _different_ images based on the screen size.
 * Example: '/images/mobile.jpg' on small screens and '/images/desktop.jpg' on screens >= 900px.
 */
export const CloudinaryPicture = ({
  alt,
  sources,
  maxContainerWidth,
  imageProps = {},
  pictureProps = {},
}: CloudinaryPictureProps) => {
  const { style, fetchPriority, ...imagePropsRest } = imageProps;
  const descendingSources = [...sources].sort(
    (a, b) => b.minWidth - a.minWidth,
  );

  return (
    <picture {...pictureProps}>
      {descendingSources.map(({ cloudinaryAsset, minWidth, sizes }) => (
        <source
          key={minWidth}
          media={minWidth > 0 ? `(min-width: ${minWidth}px)` : undefined}
          srcSet={getPictureSrcSet({ cloudinaryAsset })}
          // The browser decides which image to request based on the image width * screen resolution,
          // e.g., for an image displayed at full-width (100vw) on a 400px 3x screen, the browser would request a 1200px image
          // To prevent requesting **huge** images, we cap the image fidelity to 2x by telling the browser
          // that the image will be displayed at 2/3 its size on screens with >= 3x resolution
          // Source: https://stackoverflow.com/questions/69586765/responsive-images-with-srcset-sizes-media-queries-prevent-loading-huge-image/69637293#69637293
          // No need to specify sizes here since min-width media query is handled by the media attribute
          sizes={getSizes({
            maxContainerWidth,
            sizes,
            dprs: [{ resolution: 3, reduction: 2 / 3 }],
          })}
          width={cloudinaryAsset?.width || 0}
          height={cloudinaryAsset?.height || 0}
        />
      ))}

      <img
        alt={alt}
        src={getPictureSrc({
          cloudinaryAsset:
            descendingSources[descendingSources.length - 1].cloudinaryAsset,
        })}
        style={{ width: '100%', height: 'auto', ...style }}
        // @ts-expect-error fetchpriority hasn't been added to the React types yet
        // see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/63178 for more information
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={fetchPriority}
        {...imagePropsRest}
      />
    </picture>
  );
};
