import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from 'react';
import { observe } from 'react-intersection-observer';

export const useStickyCtaContext = () => {
  const context = useContext(stickyContext);

  const { setStickyContainerTarget, isStickyCtaHidden } = context;

  return {
    setStickyContainerTarget,
    isStickyCtaHidden,
  };
};

type StickyCtaContextValue = {
  setStickyContainerTarget: ((node?: Element | null) => void) | undefined;
  isStickyCtaHidden: boolean | undefined;
};

const stickyContext = createContext<StickyCtaContextValue>({
  setStickyContainerTarget: undefined,
  isStickyCtaHidden: undefined,
});

export const StickyCtaContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isStickyCtaHidden, setIsStickyCtaHidden] = useState(true);
  const stickyTargetsInView = useRef<Map<Element, boolean>>(new Map());
  const cleanupObservableFunctions = useRef<Set<() => void>>(new Set());

  // this function should be set as a ref for an element that when in view
  // will trigger the sticky cta to be hidden
  const setStickyContainerTarget = useCallback((node?: Element | null) => {
    if (node) {
      stickyTargetsInView.current.set(node, false);

      // using observe instead of useInView because useInView doesn't support
      // multiple targets: https://github.com/thebuilder/react-intersection-observer#low-level-api
      const unobserve = observe(node, inView => {
        stickyTargetsInView.current.set(node, inView);

        // if any of the sticky targets are in view, hide the sticky cta
        setIsStickyCtaHidden(
          [...stickyTargetsInView.current.values()].some(Boolean),
        );
      });

      // add the cleanup function to the set so it can be called when the
      // component unmounts
      cleanupObservableFunctions.current.add(unobserve);
    }
  }, []);

  useEffect(() => {
    // cleanup function to unobserve all elements when the component unmounts
    const cleanupFunctionHandler = () => {
      const cleanupFunctions = Array.from(cleanupObservableFunctions.current);

      cleanupFunctions.forEach(unobserve => {
        unobserve();
      });
    };

    return cleanupFunctionHandler;
  }, []);

  return (
    <stickyContext.Provider
      value={{
        setStickyContainerTarget,
        isStickyCtaHidden,
      }}
    >
      {children}
    </stickyContext.Provider>
  );
};
